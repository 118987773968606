.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-title {
    font-family: 'Alphabetized Cassette Tapes', sans-serif;
    color: white;
    font-size: 3em;
  }
  
  .footer-title a {
    color: white;
    margin: 0 5px;
    text-decoration: none;
  }
  
  .lamp-image-small {
    position: absolute;
    margin-left: -10px;
  }
  
  .linkedin-icon {
    margin-left: 1px;
  }
  
  @media (max-width: 700px) {
    .footer-title {
      font-size: 2em;
    }
  
    .lamp-image-small {
      position: absolute;
      transform: scale(0.5);
    }

    .footer {
      margin-bottom: 20px;
    }
  }