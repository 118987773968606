.proposition-display-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    margin-left: 0;
    margin-right: 0;
    width: 80%;
}

.proposition-display-container .button-new-proposition {
    color: white;
    border-color: white;
}

@media (min-height: 700px) and (max-height: 800px) {
    .proposition-display-container {
        top: calc(50% + 20px);
    }
}

@media (max-height: 700px) {
    .proposition-display-container {
        top: calc(50% + 40px);
    }
}

@media (max-width: 600px) and (max-height: 800px) {
    .proposition-display-container .MuiTypography-root {
        font-size: 1.5em;
    }
}
