* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  background-color: #336699;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.app-container {
  flex-grow: 1;
}

.developed-by {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.5em;
  color: #99CCFF;
}

.developed-by a {
  color: #99CCFF;
}

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.login-container .MuiTextField-root {
  margin-bottom: 10px;
}

.login-container .login-button {
  margin-top: 10px;
}

.login-container .MuiInputBase-input {
  color: white;
}

.login-container .MuiFormLabel-root,
.login-container .MuiInputLabel-outlined {
  color: white !important;
}

.login-container .MuiOutlinedInput-root {
  & .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
  }
}

.login-container .MuiOutlinedInput-input {
  color: white;
}


@media (min-height: 700px) and (max-height: 800px) {
  .login-container {
    top: calc(50% + 20px);
  }
}

@media (max-width: 700px) {
  .developed-by {
    text-align: center;
    bottom: 10px;
  }

  .login-container {
    top: calc(50% + 40px);
  }
}
