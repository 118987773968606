/* Default styles for .app-header */
.app-header {
    background-color: #336699;
    color: white;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 20px;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.flag-icon svg {
    width: 32px;
    height: 32px;
}

.custom-select {
    color: #336699;
    margin-left: 10px;
    margin-right: 20px;
    border: 1px solid #336699;
    outline: 1px solid #336699;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-title {
    position: relative;
    text-align: center;
    font-family: 'Alphabetized Cassette Tapes', sans-serif;
    font-size: 8em;
    margin-right: 40px;
}

.header-title p {
    display: inline; 
}

.lamp-image {
    position: absolute;
    margin-top: 15px;
    margin-left: 10px;
    transform: scale(1.5);
}

@media (min-width: 810px) and (max-width: 1500px) {
    .header-title {
        font-size: 6em;
        margin-top: 80px;
    }
}

@media (max-width: 810px) {
    .header-title {
        font-size: 4em;
        margin-top: 60px;
    }
    .lamp-image {
        margin-top: 15px;
        margin-left: 10px;
    }
}

@font-face {
    font-family: 'Alphabetized Cassette Tapes';
    src: url('resources/fonts/alphabetized-cassette-tapes.ttf') format('truetype');
}
